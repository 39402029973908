<template>
  <v-pagination
    outlined
    :style="`background: ${$theme.background};`"
    :color="$theme.primary"
    total-visible="7"
    v-model="get_clientes_filtros.page"
    :length="get_clientes.totalPages"
    @input="setPage($event)"
  ></v-pagination>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_clientes", "get_clientes_filtros"])
  },
  methods: {
    ...mapActions(["listar_clientes"]),
    setPage(page) {
      if (page) {
        console.log("page", page);
        this.get_clientes_filtros.page = page;
        this.listar_clientes();
      }
    }
  }
};
</script>
